.application {
    text-align: center;
    height: 100vh;
    display: flex;
    width: 100vw;
  }
  
.btn-toggle {
  display: none;
  position: absolute;
  left: 15px;
  top: 14px;
  z-index: 10;
  
  @media (max-width: 768px) {
    display: block;
  }
}

.circle-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background-color: #2b2b2b;
  border-radius: 20%;
  outline-style: solid;
  outline-width: 2px;
  outline-color: black
}
