.scrollable-background {
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: scroll;
  background-color: rgba(0,0,50,.5);
  background-blend-mode: multiply;
}

.flex-aligned {
  display: flex;
  align-items: center;
}

.scrollable-background::-webkit-scrollbar {
  display: none;
  padding-bottom: 100px;
}

.white-box-content {
  padding: 40px;
  background-color: #fff;
  border-style: solid;
  border-color: black;
  border-radius: 20px;
  margin: auto;
  margin-top: 64px;
  margin-bottom: 64px;

  @media (max-width: 768px) {
    width: 500px;
  }
}

.logo-href {
  width: 180px;
  margin-left: 16px;
  
  @media (max-width: 768px) {
    width: 98px;
    margin-left: 0px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.small-info {
  width: 300px;
  
  @media (max-width: 768px) {
    width: 150px;
  }
}

.large-info {
  width: 600px;

  @media (max-width: 768px) {
    width: 270px;
  }
}

.href-title { 
  font-size: 24px;
  text-align: center;
  font-family: sans-serif;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.title1-text { 
  color: #333;
  font-size: 28px ;
  font-weight: bold;
  text-align: center;
  font-family: sans-serif;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 1.6em;
  }
}

.title6-text {
  font-size: 1.8rem;
  font-weight: bold;
  color: black;
  line-height: 0.1;
}

.text-input {
  border-style: solid;
  border-color: black;
  border-radius: 8px;
  width: 200px;

  @media (max-width: 768px) {
    width: 140px;
  }
}
  
.paragraph-text {
  font-size: 18px;
  color: #333;
  text-align: justify;
  font-family: sans-serif;
  line-height: 1.6em;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.blue-button {
  background-color: dodgerblue;
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-style: solid;
  border-color: black;
  border-radius: 20px;
  border-width: 2px;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }

}

.blue-button:hover {
  background-color: royalblue;
}

.channel-logo {
  width: 40px; 
  height: 40px; 
  margin-right: 10px; 
  border-radius: 30px;
}

.video-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.logo-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.copyable-text {
  font-size: 0.8rem;
  line-height: 1.6;
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: pre-wrap; 
  word-wrap: break-word;
  overflow-wrap: break-word;
  background-color: lightgray; 
  padding: 2px;
  border: 1px solid #000;
  margin-bottom: 5px;
  margin-top: 20px;
}

.logo-img {
  max-width: 110px;
  height: auto;
  object-fit: contain;
}

.logo-wrapper {
  margin: 10px; 
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}